/** @jsx jsx */
import { Styled, Flex, Box, jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const ProductInfo = props => (
  <Box sx={{ mb: 3, textAlign: ["center", null, "left"] }}>
    <Box sx={{ color: "#888" }} children={`${props.label}`} />
    <Box sx={{ fontSize: 3 }}>{props.children}</Box>
  </Box>
)

export default ({ data }) => {
  const {
    name,
    image,
    alcoholContent,
    blend,
    terroir,
    volume,
    description,
    dosage,
    producer,
    price,
    productNumber,
    externalLink,
  } = data.contentfulProduct
  return (
    <Layout>
      <SEO title={`${name} – ${producer.name}`} />
      <Box sx={{ textAlign: "center" }}>
        <Styled.h1
          sx={{
            mt: 4,
            mb: 0,
            fontSize: [4, 5],
            lineHeight: "heading",
          }}
        >
          {name}
        </Styled.h1>
        <Styled.a as={Link} to={`/tuottajat/${producer.slug}`}>
          {producer.name}
        </Styled.a>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", null, "1fr 1fr 1fr"],
          mx: "auto",
          my: [4, 5],
          alignItems: "center",
        }}
      >
        {description && (
          <Styled.p
            sx={{
              m: 0,
              fontSize: [3, 4],
              fontFamily: "display",
              textAlign: ["center", null, "left"],
            }}
          >
            {description.description}
          </Styled.p>
        )}

        <Flex sx={{ justifyContent: "center", py: 4 }}>
          <Img
            fluid={image.fluid}
            imgStyle={{ objectFit: "contain" }}
            sx={{ width: "100%", maxHeight: "60vh", px: 4 }}
          />
        </Flex>
        <Box>
          <ProductInfo label="Rypäleet">{blend}</ProductInfo>
          <ProductInfo label="Tarhat/kylät">{terroir}</ProductInfo>
          <ProductInfo label="Tilavuus">{volume}</ProductInfo>
          <ProductInfo label="Alkoholi">{alcoholContent}</ProductInfo>
          <ProductInfo label="Sokeripitoisuus">{dosage}</ProductInfo>
          {productNumber && (
            <ProductInfo label="Tuotenumero">
              {productNumber}
              {externalLink && (
                <Styled.a
                  href={externalLink}
                  target="_blank"
                  sx={{ display: "block", fontSize: 2 }}
                >
                  Alkon tilausvalikoima
                </Styled.a>
              )}
            </ProductInfo>
          )}
          {price && (
            <ProductInfo label="Ravintolahinta">
              {price.toFixed(2)} €{" / "}
              {(price * 1.24).toFixed(2)} €
            </ProductInfo>
          )}
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      name
      slug
      image {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      alcoholContent
      blend
      terroir
      price
      description {
        description
      }
      dosage
      volume
      producer {
        name
        slug
      }
      productNumber
      externalLink
    }
  }
`
